<template>
  <v-container class="full-height">
    <v-row class="full-height">
      <v-col
        ref="targetInfiniteDiv"
        class="full-height"
        style="position: relative">
        <div
          v-infinite-scroll="loadMore"
          :infinite-scroll-disabled="busy">
          <div
            v-for="(sentence, index) in sentences"
            :key="index">
            <SentenceCard :sentence="sentence"></SentenceCard>
            <v-divider class="my-5"></v-divider>
          </div>
          <ScrollLoding :loading="busy"></ScrollLoding>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SentenceCard from '@/components/Sentence/SentenceCard.vue'
import ScrollLoding from '@/components/base/scrollLoding'

export default {
  name: 'Sentence',
  inject: ['theme'],

  components: {
    ScrollLoding,
    SentenceCard
  },

  data: () => ({
    limit: 7,
    offset: 1,
    count: 0,
    busy: false,
    sentences: []
  }),

  computed: {
    isAllData () {
      return this.sentences.length >= this.count
    }
  },

  async created () {
    await this.initData()
  },

  watch: {
    $route () {
      this.listSentences()
    }
  },

  methods: {
    /**
     * 全部数据且没有填满 停止
     * 全部数据且填满了 停止
     * 没全部数据且没有填满 获取
     * 没全部数据且填满了 停止
     *
     * 因为这个发现了一个BUG。。findAndCountAll 需要加上 distinct: true 才不会加上 include count
     */
    async initData () {
      await this.addSentences()

      const dom = this.$refs.targetInfiniteDiv
      const coverHeight = dom.clientHeight
      let hasCover = dom.lastChild.offsetTop + dom.lastChild.clientHeight >= coverHeight
      while (!this.isAllData && !hasCover) {
        await this.addSentences()
        hasCover = dom.lastChild.offsetTop + dom.lastChild.clientHeight >= coverHeight
      }
    },

    // TODO: 第一次加载时如果默认数据太少，未出现滚动条则无法加载后续数据
    async loadMore () {
      if (this.isAllData) {
        return
      }
      this.busy = true
      await this.addSentences()
      this.busy = false
    },

    async addSentences () {
      const data = await this.$http.Sentence.list(Object.assign({
        limit: this.limit,
        offset: this.offset++
      }, this.$route.query))
      this.count = data && data.count ? data.count : 0
      this.sentences.push(...(data && data.rows ? data.rows : []))
    },

    listSentences () {
      this.sentences = []
      this.offset = 1
      this.addSentences()
    }
  }
}
</script>
